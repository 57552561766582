<template>
  <v-row class="pa-3">
    <v-col xs12>
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        size="100"
        color="primary"
      ></v-progress-circular>
      <div v-if="data.length === 0 && !isLoading">
        <v-alert :value="true" type="info" class="white--text mx-3">
          Maaf data riwayat obat pasien tidak ditemukan
        </v-alert>
      </div>
      <div v-for="(data, indexData) in data" :key="indexData" class="my-2">
        <v-card flat class="card-visited text-left pa-3" :key="indexData">
          <label class="font-weight-medium black--text"
            >Kunjungan {{ data.date }}
          </label>
        </v-card>
        <v-expansion-panels
          v-if="takeHomeDrugs[indexData].length > 0"
          :key="indexData"
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              color="#F8F9FF"
              class="py-0"
              @click="toggle(indexData)"
            >
              <label
                :style="{
                  color: selectedIndex === indexData ? '#137BC0' : '#3F484A',
                }"
                class="text-subtitle-2"
                >OBAT DIBAWA PULANG</label
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#DEEEF9" class="px-0">
              <v-row no-gutters>
                <v-col class="pr-0">
                  <v-data-table
                    :headers="table.headers"
                    :items="takeHomeDrugs[indexData]"
                    :items-per-page="takeHomeDrugs[indexData].length"
                    hide-default-footer
                    style="background-color: transparent !important;"
                  >
                    <template v-slot:[`item.no`]="{ item }">
                      {{ takeHomeDrugs[indexData].indexOf(item) + 1 }}
                    </template>
                    <template v-slot:[`item.roa`]="{ item }">
                      {{ item.roa || ' - ' }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels
          v-if="table.items[indexData].length > 0"
          :key="indexData"
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              color="#F8F9FF"
              class="py-0"
              @click="toggle(indexData)"
            >
              <label
                :style="{
                  color: selectedIndex === indexData ? '#137BC0' : '#3F484A',
                }"
                class="text-subtitle-2"
                >CATATAN PEMBERIAN OBAT</label
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-0">
              <v-container fluid class="table-layout">
                <v-row class="header-container">
                  <v-col cols="6" class="pa-0">
                    <div class="grid header px-2">
                      <label class="text-left body-2 font-weight-medium"
                        >No</label
                      >
                      <label class="text-left body-2 font-weight-medium"
                        >Nama Obat</label
                      >
                      <label class="text-left body-2 font-weight-medium"
                        >Dosis</label
                      >
                      <label class="text-left body-2 font-weight-medium"
                        >Aturan Pakai</label
                      >
                      <label class="text-left body-2 font-weight-medium"
                        >Rute</label
                      >
                      <label class="text-left body-2 font-weight-medium"
                        >Diberikan di</label
                      >
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pa-0 scrollable"
                    @scroll="scrollingHeader()"
                    ref="header"
                  >
                    <table class="table-header">
                      <thead>
                        <tr>
                          <th
                            style="min-width: 100px;"
                            v-for="header in headerDate[indexData]"
                            :key="header.date"
                            :colspan="header.time.length"
                          >
                            {{ header.date }}
                          </th>
                        </tr>
                        <tr>
                          <template v-for="header in headerDate[indexData]">
                            <th
                              v-for="(time, indexTime) in header.time"
                              :key="indexTime"
                            >
                              {{ time.time }}
                            </th>
                          </template>
                        </tr>
                      </thead>
                    </table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="pa-0">
                    <v-data-iterator
                      :items="table.items[indexData]"
                      :items-per-page="table.items[indexData].length"
                      hide-default-footer
                      item-key="no"
                      id="scroll-target"
                      disable-sort
                      single-expand
                      v-scroll:#scroll-target="onScrollX"
                    >
                      <template v-slot:default="{ items }">
                        <v-card flat tile>
                          <v-expansion-panels
                            accordion
                            flat
                            light
                            tile
                            class="elevation-0"
                          >
                            <v-expansion-panel
                              class="elevation-0"
                              v-for="(arr, indexItem) in items"
                              :key="`item-${indexItem}`"
                            >
                              <v-expansion-panel-header
                                style="border-top: none !important;"
                                class="grid data pa-0 data-item"
                                :class="{ disable: !arr.isContinued }"
                                expand-icon=""
                                :color="arr.isContinued ? '' : '#F4F5F5'"
                              >
                                <label class="text-center body-2">{{
                                  arr.no
                                }}</label>
                                <div class="d-flex flex-column">
                                  <label class="text-left body-2 pr-3">{{
                                    arr.nonracik.drugName || arr.racik.drugName
                                  }}</label>
                                  <span
                                    class="mt-1"
                                    style="font-size: 13px; color: grey"
                                    >{{
                                      arr.nonracik.id_drugs
                                        ? (arr.nonracik.unit_dosage
                                            ? arr.nonracik.dosage
                                            : '') +
                                          ' ' +
                                          (arr.nonracik.unit_dosage || '')
                                        : ''
                                    }}</span
                                  >
                                </div>
                                <label class="text-left body-2">{{
                                  arr.racik.dosage + arr.racik.unit_dosage ||
                                    arr.nonracik.dosage +
                                      arr.nonracik.unit_dosage ||
                                    '-'
                                }}</label>
                                <label class="text-left body-2">{{
                                  arr.racik.usageRule || arr.nonracik.usageRule
                                }}</label>
                                <label class="text-left body-2">{{
                                  arr.racik.route || arr.nonracik.route
                                }}</label>
                                <label class="text-left body-2 pr-2">{{
                                  arr.racik.given || arr.nonracik.given
                                }}</label>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content
                                class="py-3"
                                color="#F4F5F5"
                              >
                                <div class="expand-content">
                                  <span></span>
                                  <span
                                    class="grey--text font-weight-medium caption text-left d-flex align-center"
                                    >Diresepkan oleh
                                    {{
                                      arr.racik.staff || arr.nonracik.staff
                                    }}</span
                                  >
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card>
                      </template>
                    </v-data-iterator>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pa-0 table-time-container scrollable"
                    ref="time"
                    @scroll="scrolling()"
                  >
                    <table class="table-grid">
                      <tbody>
                        <tr
                          v-for="(props, index) in table.items[indexData]"
                          :key="index"
                        >
                          <template
                            v-for="(item, index) in headerDate[indexData]"
                          >
                            <td
                              v-for="(time, timeIndex) in item.time"
                              :key="'date' + index + 'time-' + timeIndex"
                              :class="{
                                'width-grid-2':
                                  headerDate[indexData][index].time.length ===
                                  1,
                                'width-grid-1':
                                  headerDate[indexData][index].time.length > 1,
                              }"
                            >
                              <v-flex class="highalert">
                                <v-icon
                                  medium
                                  :color="
                                    setIcon2(props, item.date, time.time).color
                                  "
                                >
                                  {{
                                    setIcon2(props, item.date, time.time).icon
                                  }}
                                </v-icon>
                                <div
                                  :class="
                                    setIcon2(props, item.date, time.time).class
                                  "
                                ></div>
                                <v-icon
                                  :size="12"
                                  class="alert-inner"
                                  v-if="
                                    (props.isPotentNonRacik ||
                                      props.isPotentRacik) &&
                                      setIcon2(props, item.date, time.time)
                                        .icon === 'mdi-check_circle' &&
                                      setIcon2(props, item.date, time.time)
                                        .color === '#219653'
                                  "
                                  color="#EB4747"
                                >
                                  mdi-circle
                                </v-icon>
                              </v-flex>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
                <v-row class="footer-container">
                  <v-col cols="6" class="pa-0">
                    <v-toolbar
                      color="#f4f5f5"
                      class="d-flex justify-end table-footer"
                      style="height: 75px !important"
                      light
                      flat
                    >
                      <v-toolbar-title
                        class="subtitle-1 font-weight-medium text-right"
                      >
                        Nama Petugas
                      </v-toolbar-title>
                    </v-toolbar>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pa-0 scrollable visible"
                    @scroll="scrollingFooter()"
                    ref="footer"
                  >
                    <table class="footer-table">
                      <tfoot>
                        <tr>
                          <template
                            v-for="(item, index) in headerDate[indexData]"
                          >
                            <td
                              v-for="(time, timeIndex) in item.time"
                              :key="'date' + index + 'time-' + timeIndex"
                              class="staff-col"
                              :class="{
                                'width-grid-2':
                                  headerDate[indexData][index].time.length ===
                                  1,
                                'width-grid-1':
                                  headerDate[indexData][index].time.length > 1,
                              }"
                            >
                              <div class="d-flex flex-column">
                                <v-chip
                                  small
                                  color="#B2FFD3"
                                  text-color="#219653"
                                  label
                                  class="chip-center"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <h5 v-bind="attrs" v-on="on">
                                        {{ getNameInitial(time.created_by) }}
                                      </h5>
                                    </template>
                                    <span>{{ time.created_by }}</span>
                                  </v-tooltip>
                                  <!-- <h5>PT</h5> -->
                                </v-chip>
                                <v-chip
                                  small
                                  color="#fff9e6"
                                  text-color="#e5a82e"
                                  label
                                  v-if="time.verified_by"
                                  class="chip-center mt-1"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <h5 v-bind="attrs" v-on="on">
                                        {{ getNameInitial(time.verified_by) }}
                                      </h5>
                                    </template>
                                    <span>{{ time.verified_by }}</span>
                                  </v-tooltip>
                                  <!-- <h5>PT</h5> -->
                                </v-chip>
                              </div>
                            </td>
                          </template>
                        </tr>
                      </tfoot>
                    </table>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';

const axios = require('axios');

export default {
  name: 'DrugComponent',
  props: {
    idPatient: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
      takeHomeDrugs: [],
      timetableWidth: 1,
      drugLog: [],
      selectedIndex: null,
      distinct_date: [],
      scrollTop: 0,
      data: [],
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            width: '3%',
            sortable: false,
          },
          {
            text: 'Nama Obat',
            value: 'name',
            sortable: false,
            width: '22%',
          },
          {
            text: 'Jenis Obat',
            value: 'type',
            sortable: false,
            width: '8%',
          },
          {
            text: 'Dosis',
            value: 'dosage',
            sortable: false,
            width: '10%',
          },
          {
            text: 'Sediaan',
            value: 'roa',
            sortable: false,
            width: '7%',
          },
          {
            text: 'Jumlah Diberikan',
            value: 'amount_given',
            sortable: false,
            width: '5%',
          },
          {
            text: 'Rute',
            value: 'route',
            sortable: false,
            width: '10%',
          },
          {
            text: 'Aturan Pakai',
            value: 'usage',
            sortable: false,
            width: '10%',
          },
          {
            text: 'Aturan Lainnya',
            value: 'description',
            sortable: false,
            width: '12%',
          },
          {
            text: 'Aturan Umum',
            value: 'general_rule',
            sortable: false,
            width: '13%',
          },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
      headerDate: [],
      schedule: [],
      drugSchedule: [],
      inputSchedule: {
        drugSchedule: '',
        dateDrug: moment(new Date()).format('YYYY-MM-DD'),
        time: moment(new Date()).format('HH:mm'),
      },
    };
  },
  mounted() {
    this.showAllDrugs();
  },
  methods: {
    toggle(indexData) {
      this.selectedIndex = this.selectedIndex === indexData ? null : indexData;
    },
    scrolling() {
      const time = this.$refs.time[0];
      const header = this.$refs.header[0];
      const footer = this.$refs.footer[0];

      footer.scrollLeft = time.scrollLeft;
      header.scrollLeft = time.scrollLeft;
    },
    scrollingFooter() {
      const time = this.$refs.time[0];
      const header = this.$refs.header[0];
      const footer = this.$refs.footer[0];

      time.scrollLeft = footer.scrollLeft;
      header.scrollLeft = footer.scrollLeft;
    },
    scrollingHeader() {
      const time = this.$refs.time[0];
      const header = this.$refs.header[0];
      const footer = this.$refs.footer[0];

      time.scrollLeft = header.scrollLeft;
      footer.scrollLeft = header.scrollLeft;
    },
    getNameInitial(name) {
      return name
        ? name
            .split(' ')
            .splice(0, 3)
            .map(s => s[0])
            .join('')
            .toUpperCase()
        : '';
    },
    setIcon(log, date, time) {
      const entry = log.history.find(
        arr => arr.date === date && arr.time === time,
      );
      if (entry) {
        switch (entry.drug_status) {
          case 'given':
            return { icon: 'check_circle', color: '#27AE60' };
          case 'pending':
            return { icon: 'remove', color: '#3F484A' };
          case 'discontinued':
            return { icon: 'cancel', color: '#EB5757' };
          default:
            return { icon: '', color: '' };
        }
      }
      return false;
    },
    setIcon2(log, date, time) {
      const entry = log.history.filter(
        arr => arr.date === date && arr.time === time,
      );
      if (entry.length > 0) {
        if (
          entry.findIndex(arr => arr.drug_status === 'given') !== -1 &&
          log.nonracik?.isHighAlert
        )
          return { icon: 'mdi-check-circle', color: '#219653', class: 'high' };
        if (entry.findIndex(arr => arr.drug_status === 'given') !== -1)
          return { icon: 'mdi-check-circle', color: '#219653', class: '' };
        if (entry.findIndex(arr => arr.drug_status === 'discontinued') !== -1)
          return { icon: 'mdi-close-circle', color: '#EB4747', class: '' };
        if (
          entry.findIndex(
            arr =>
              arr.drug_status === 'pending' || arr.drug_status === 'postponed',
          ) !== -1
        )
          return { icon: 'mdi-clock-time-four-outline', color: '', class: '' };
        return { icon: '', color: '', class: '' };
      }
      return false;
    },
    onScrollX(e) {
      const element = document.getElementById('time-target');
      element.scrollTop = e.target.scrollTop;
    },
    showAllDrugs() {
      this.isLoading = true;
      const id = this.$route.params.id;
      axios
        .get(Constant.apiUrl.concat(`/patient/recipe/all/${id}`))
        .then(response => {
          const dataItem = response.data.data;

          let dataEmr = [];
          dataEmr = dataItem.map(item => {
            return {
              date: item.dateSplit,
              takeHome:
                item.takeHomeDrugs.length > 0
                  ? item.takeHomeDrugs.map(recipe => {
                      return {
                        name: recipe.name,
                        roa: recipe.roa,
                        description: recipe.description,
                        usage: recipe.usage,
                        dosage: recipe.strength,
                        type: recipe.isMix === false ? 'Non Racik' : 'Racik',
                        package: recipe.isMix === true ? recipe.packaging : '',
                        general_rule: recipe.general_rule || '',
                        route: recipe.route,
                        amount_given: recipe.quantity,
                      };
                    })
                  : [],
              cpo: {
                headerDate:
                  item.cpoDrugs.length > 0
                    ? item.cpoDrugs[0].distinct_date.map(arr => ({
                        ...arr,
                        time: arr.time.filter((t, i) => {
                          return (
                            arr.time.findIndex(o => o.time === t.time) === i
                          );
                        }),
                      }))
                    : [],
                items:
                  item.cpoDrugs.length > 0
                    ? item.cpoDrugs[0].drugs
                        .sort((a, b) => b.is_continued - a.is_continued)
                        .map((drug, index) => {
                          return {
                            no: index + 1,
                            isContinued: drug.is_continued,
                            isExtra: drug.is_extra_drugs,
                            isPotentNonRacik: drug.nonracik?.isPotent || false,
                            isPotentRacik:
                              drug.racik?.drugs[0]?.isPotent || false,
                            id_recipe: drug.id_recipe,
                            _id: drug._id,
                            nama: drug.nonracik?.name || drug.racik?.name,
                            time_delivery: drug.time_delivery
                              ? drug.time_delivery.map(arr => ({
                                  ...arr,
                                  text: `${arr.date} ${arr.time}`,
                                }))
                              : [],
                            racik:
                              drug.racik?.drugs.length > 0
                                ? {
                                    ...drug.racik,
                                    drugName: drug.racik.name,
                                    given: item.cpoDrugs[0].patient_status
                                      .toLowerCase()
                                      .split(' ')
                                      .map(
                                        s =>
                                          s.charAt(0).toUpperCase() +
                                          s.substring(1),
                                      )
                                      .join(' '),
                                    quantity: drug.racik.quantity,
                                    drugType: 'Obat Racik',
                                    dosage: drug.racik.dosage,
                                    dose: drug.racik.usage,
                                    price: drug.racik.price,
                                    // staff: drug.racik.staff,
                                    note: drug.racik.description,
                                    date: moment(drug.racik.date)
                                      .locale('id')
                                      .format('DD MMMM YYYY hh:mm [WIB]'),
                                    drugs: drug.racik.drugs,
                                    route: drug.racik.route,
                                    packaging: drug.racik.packaging,
                                    isConfirmed: drug.racik.isApproved,
                                    generalRules: drug.racik.general_rule,
                                    usageRule: drug.racik.usage,
                                    staff: drug.timestamps.created_by,
                                    time_delivery: drug.racik.time_delivery,
                                    type:
                                      drug.racik.drugs.length > 0
                                        ? 'racik'
                                        : '',
                                  }
                                : {},
                            nonracik: drug.nonracik?.id_drugs
                              ? {
                                  ...drug.nonracik,
                                  note: drug.nonracik.description,
                                  drugType: 'Obat Non Racik',
                                  dosage: drug.nonracik.dosage,
                                  generalRules: drug.nonracik.general_rule,
                                  id_drugs: drug.nonracik.id_drugs,
                                  drugName: drug.nonracik.name,
                                  packaging: drug.nonracik.packaging,
                                  preparation: drug.nonracik.preparation,
                                  quantity: drug.nonracik.quantity,
                                  requestedQuantity:
                                    drug.nonracik.requested_quantity,
                                  route: drug.nonracik.route || '',
                                  dose: `${drug.nonracik.dosage} ${drug.nonracik.unit_dosage}`,
                                  usageRule: drug.nonracik.usage,
                                  id: drug._id,
                                  isHighAlert: drug.nonracik.isHighAlert,
                                  staff: drug.timestamps.created_by,
                                  date: moment(drug.nonracik.date)
                                    .locale('id')
                                    .format('DD MMMM YYYY hh:mm [WIB]'),
                                  isConfirmed: drug.nonracik.isApproved,
                                  unit_dosage: drug.nonracik.unit_dosage,
                                  type: drug.nonracik.id_drugs
                                    ? 'nonracik'
                                    : '',
                                  time_delivery: drug.nonracik.time_delivery,
                                  given: item.cpoDrugs[0].patient_status
                                    .toLowerCase()
                                    .split(' ')
                                    .map(
                                      s =>
                                        s.charAt(0).toUpperCase() +
                                        s.substring(1),
                                    )
                                    .join(' '),
                                }
                              : {},
                            history: drug.history.map(unit => {
                              return {
                                ...unit,
                              };
                            }),
                            created_at: drug.timestamps.created_at,
                          };
                        })
                    : [],
              },
            };
          });
          this.data = dataEmr;

          const takeHomeData = dataEmr.map(item => item.takeHome);

          for (let i = 0; i < takeHomeData.length; i++) {
            takeHomeData[i] = takeHomeData[i].flat();
          }
          this.takeHomeDrugs = takeHomeData;

          this.headerDate = dataEmr.map(item => item.cpo.headerDate);

          this.table.items = dataEmr.map(item => item.cpo.items);
        })
        .catch(error => {
          if (error) {
            this.table.items = [];
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.take-home {
  display: grid;
  grid-template-columns: 30px 160px 70px 100px 80px 120px 150px 150px 150px;
}

.border-blue {
  border: 1px solid #137bc0;
}

.header-top {
  height: 40px;
}

::v-deep td {
  border-bottom: 1px solid #86bcdb !important;
}

::v-deep thead tr th {
  border-bottom: 1px solid #86bcdb !important;
  border-top: 1px solid #86bcdb !important;
}
::v-deep .v-expansion-panel-header {
  background-color: #fff !important;
  border-top: 1px solid #e0e0e0 !important;
  border-left: 1px solid #e0e0e0 !important;
  border-right: 1px solid #e0e0e0 !important;
  border-bottom: 1px solid #e0e0e0 !important;
}
::v-deep .v-expansion-panel-content {
  background-color: #fff !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-left: 1px solid #e0e0e0 !important;
  border-right: 1px solid #e0e0e0 !important;
}
::v-deep .theme--light.v-table thead tr:first-child {
  border-bottom: none;
}
::v-deep .theme--light.v-table {
  background-color: #deeef9 !important;
  color: rgba(0, 0, 0, 0.87);
}

::v-deep table.v-table {
  border-collapse: inherit;
}
::v-deep table.v-table thead tr {
  height: 30px;
}
// ::v-deep tbody {
//   vertical-align: top;
// }
//.time-table {
//  ::v-deep thead {
//    position: absolute;
//    top: 0;
//  }
//  ::v-deep tbody {
//    position: absolute;
//    top: 20%;
//  }
//}
.table-container {
  min-width: 450px;
  max-width: 450px;
  &.mini {
    min-width: 650px;
    max-width: 650px;
  }
  overflow-x: auto;
  padding: 0px;
  margin: 0px;
  position: relative;
  height: fit-content;
}
.drugtable-container {
  position: relative;
  height: fit-content;
}
.timetable {
  max-height: 300px;
  margin: 0px;
  padding: 0px;
  margin-top: 56px;
  margin-bottom: 65px;
  overflow-y: hidden;
  overflow-x: visible;

  & ::v-deep thead {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    z-index: 200;
    tr {
      height: 28px;
    }
  }
  & ::v-deep tfoot {
    overflow-x: hidden;
    position: absolute;
    bottom: 0px;
  }
  & ::v-deep th {
    white-space: unset !important;
  }
  & ::v-deep .row-datatable {
    min-height: 60px !important;
    max-height: 60px !important;
    border: none !important;
  }
  ::v-deep .v-badge--overlap .v-badge__badge {
    top: 0px;
    right: 0px;
    width: 10px;
    height: 10px;
  }
}
.datatable {
  max-height: 300px;
  max-width: 300px;
  min-width: 300px;
  margin: 0px;
  padding: 0px;
  margin-top: 56px;
  margin-bottom: 65px;
  overflow-y: auto;
  margin-left: 3px;
  &::-webkit-scrollbar {
    width: 5px !important;
    border-radius: 5px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px !important;
    border-radius: 5px;
    background-color: rgb(163, 163, 163) !important;
    // background-color: black !important;
  }
  & ::v-deep thead {
    position: absolute;
    top: 0px;
    z-index: 200;
    white-space: unset !important;
    tr {
      height: 28px;
    }
  }
  & ::v-deep th {
    white-space: unset !important;
  }
  &.notdoctor {
    max-width: 450px;
    min-width: 450px;
    & ::v-deep thead {
      position: absolute;
      top: 0px;
      z-index: 200;
      white-space: unset !important;
      tr {
        height: 56px;
      }
    }
  }
  & ::v-deep .row-datatable {
    cursor: pointer;
    min-height: 60px !important;
    max-height: 60px !important;
    // border: none !important;
    &.expanded {
      border-left: 10px solid #349cdc !important;
    }
    &.inactive {
      border-left: 10px solid red !important;
      background: #f3f3f3 !important;
    }
  }
}

.expand-content {
  display: grid;
  grid-template-columns: 20% 35% 20% 25%;
  .action-button {
    width: 120px;
    height: 28px;
    background-color: white !important;
  }
}
.grid {
  display: grid;
  width: 100%;

  &.disable {
    color: #a3a5a5;
  }
  &.data {
    grid-template-columns: 5% 31% 15% 15% 13% 21%;
  }
}
.table-footer {
  z-index: 10;
  position: sticky;
  bottom: -11px;
  border-bottom: none !important;
  border-right: none !important;
  border-top: 1px #9ca2a5 solid !important;
  border-left: none !important;
}
.description {
  padding: 5px 10px;
  background-color: #f0f9ff;
  border: 0.5px solid #bdbdbd;
  border-radius: 3px;
}

.card-visited {
  background: #f8f9ff;
}

.header-grid {
  padding-left: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr 2fr);
  row-gap: 5px;
}
.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}
.headline {
  color: #3498db;
}

.border {
  border-left: 5px solid #3498db !important;
  border-radius: none;
}

.table-time-container {
  overflow-x: auto !important;
}
.item-expand {
  color: #a6adb4;
}

.table-layout {
  max-height: 63vh;
  margin-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-container {
  background: #f4f5f5;
  position: sticky;
  top: -12.6px;
  height: 70px;
  z-index: 100;
  border-bottom: 1.2px solid #9ca2a5 !important;
  border-top: 1.2px solid #9ca2a5 !important;
}

.footer-container {
  position: sticky;
  bottom: -13px;
  z-index: 100;
}

.scrollable {
  overflow-x: auto;
  position: relative;
  &::-webkit-scrollbar {
    background: #c2c2c2;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }

  &.visible {
    &::-webkit-scrollbar {
      background: #c2c2c2;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(140, 138, 138);
    }
  }
}

.table-layout::-webkit-scrollbar {
  background: #c2c2c2;
  height: 8px;
}
.table-layout::-webkit-scrollbar-thumb {
  background-color: darkgray;
}
.drug-input-layout {
  display: grid;
  grid-template-columns: 20vh 40vh;
  align-items: baseline;
  grid-row-gap: 2vh;
}

::v-deep {
  thead tr th span {
    color: #1a1a1a;
  }

  tbody tr td {
    color: #272727;
    font-weight: bold;
  }

  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}

.table-grid {
  border: 1px solid #9ca2a5;
  border-collapse: collapse;
  position: relative;
  height: 100%;
  border: none !important;
  border-left: 1px solid #9ca2a5 !important;

  & ::v-deep td {
    border-bottom: none !important;
    border-top: none !important;
    border-left: none !important;
  }

  td {
    border-right: 1px solid #9ca2a5;
    min-width: 70px;
    height: 51px;
  }
}

.width-grid-1 {
  min-width: 75px;
}

.width-grid-2 {
  min-width: 100px !important;
}

@media screen and (max-width: 1703px) {
  a .table-grid {
    td {
      height: 44px;
    }
  }
}

@media screen and (min-width: 1703px) {
  .table-grid {
    td {
      height: 51px;
    }
  }
}

@media screen and (min-width: 1904px) {
  .table-grid {
    td {
      height: 60px;
    }
  }
}

.table-header {
  border-collapse: collapse;
  position: relative;

  &::v-deep thead tr th {
    border-bottom: 1px solid #9ca2a5 !important;
    border-top: none !important;

    border-right: 1px solid #9ca2a5 !important;
    border-left: 1px solid #9ca2a5 !important;
  }

  tr {
    border-top: none !important;
  }

  thead {
    height: 69px;
    background: #f4f5f5;
    position: sticky;
    top: -13px;
    z-index: 100;
    font-size: 14px;

    th {
      min-width: 70px;
    }
  }
}

.grid {
  display: grid;
  width: 100%;
  &.header {
    align-content: center;
    position: sticky;
    z-index: 10;
    top: -20px;
    color: black;
    height: 70px;
    background-color: #f4f5f5;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1.2px solid #9ca2a5;
    grid-template-columns: 5% 31% 15% 15% 15% 19%;
  }
}

.footer-table {
  border-collapse: collapse;
  td {
    border-left: 1px solid #9ca2a5;
    border-right: 1px solid #9ca2a5;
    border-bottom: none !important;
    min-width: 70px;
    height: 70px;
  }
}

.chip-center {
  margin: auto;
}

.highalert {
  position: relative;
  .high {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 5%;
    right: 28.5%;
  }
  .alert {
    position: absolute;
    top: 5%;
    right: 21.5%;
  }
  .alert-inner {
    position: absolute;
    top: 10%;
    right: 25%;
  }
}

.staff-col {
  border-bottom: 1.2px solid #9ca2a5 !important;
  border-top: 1.2px solid #9ca2a5 !important;
  background-color: #f4f5f5;
}
</style>
